import { Component } from '@angular/core';
import { theme } from 'src/themes/theme';
import { DashboardConfig } from '../common/dashboard/dashboard';

@Component({
    selector: 'uni-dashboard',
    templateUrl: './dashboard.html',
    standalone: false,
})
export class Dashboard {
    config: DashboardConfig = theme.dashboardConfigs?.main;
}
