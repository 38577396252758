import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TabService, UniModules } from '../../layout/navbar/tabstrip/tabService';
import { ErrorService } from '../../../services/common/errorService';
import { APP_METADATA } from 'src/environments/metadata';
import { ToastService, ToastTime, ToastType } from '@uni-framework/uniToast/toastService';
import { AuthService } from '@app/authService';
import { rigDate } from '@app/components/common/utils/rig-date';

const VERSION_DATE_FORMAT = 'DD.MM.YYYY HH:mm';

@Component({
    selector: 'uni-system-view',
    templateUrl: './systemView.html',
    styleUrls: ['./systemView.sass'],
    standalone: false,
})
export class UniSystemView {
    busy: boolean;

    releaseNumber: string;
    companyKey = this.authService.activeCompany.Key;

    frontend: any = {
        fullVersion: APP_METADATA.APP_VERSION,
        minVersion: APP_METADATA.APP_VERSION.substr(0, 6),
        buildDate: rigDate(APP_METADATA.APP_BUILD_DATE).format(VERSION_DATE_FORMAT),
    };

    backend: {
        fullVersion: string;
        minVersion: string;
        buildDate: string;
    };

    constructor(
        private tabService: TabService,
        private errorService: ErrorService,
        private toastService: ToastService,
        private authService: AuthService,
        private http: HttpClient,
    ) {
        this.tabService.addTab({
            name: 'Systeminformasjon',
            url: '/about/system',
            moduleID: UniModules.System,
            active: true,
        });

        this.busy = true;
        this.http
            .get('/api/status/version')
            .subscribe({
                next: (res: any) => {
                    this.backend = {
                        fullVersion: res.Version,
                        minVersion: res.Version.substr(0, 6),
                        buildDate: rigDate(res.Date).format(VERSION_DATE_FORMAT),
                    };

                    if (res.OctopusVersion) {
                        this.releaseNumber = res.OctopusVersion;
                    }
                },
                error: (err) => this.errorService.handle(err),
            })
            .add(() => (this.busy = false));
    }

    public copyCompanyKey() {
        let promise: Promise<any>;
        try {
            promise = navigator.clipboard.writeText(this.companyKey);
        } catch (err) {
            this.errorService.handle(err);
        }
        promise?.then(
            () => this.toastService.addToast('Kopiert', ToastType.good, 2),
            () =>
                this.toastService.addToast(
                    'Tilgang avvist',
                    ToastType.bad,
                    ToastTime.medium,
                    'Tilgang til utklippstavle avvist',
                ),
        );
    }
}
