import { Component, HostBinding } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '@app/authService';
import { CompanySettings, Contract } from '@uni-entities';
import { Subscription, forkJoin } from 'rxjs';
import { theme, THEMES } from 'src/themes/theme';
import { UniHttp } from '@uni-framework/core/http';
import { CompanyService } from '@app/services/common/companyService';
import { ErrorService } from '@app/services/common/errorService';
import { InitService } from '@app/services/common/initService';
import { JobTicketService } from '@app/services/common/jobTicketService';
import { ElsaContractService } from '@app/services/elsa/elsaContractService';
import { ContractType } from '@app/models';
import { environment } from 'src/environments/environment';
import { useOnboardingFlow2 } from '@app/components/common/utils/utils';

export interface CompanyInfo {
    companySettings: CompanySettings;
    isTemplate: boolean;
    valid: boolean;
}

interface RegistrationOption {
    header: string;
    priceTag?: string;
    textSections: string[];
    footerText?: string;
    buttons: {
        label: string;
        class?: string;
        action: () => void;
    }[];
}

@Component({
    selector: 'uni-register-company',
    templateUrl: './registerCompany.html',
    styleUrls: ['./registerCompany.sass'],
    standalone: false,
})
export class RegisterCompany {
    appName = theme.appName;

    tokenSubscription: Subscription;
    routeSubscription: Subscription;

    selectedCompanyType: string;
    isTest: boolean;
    busy: boolean;
    missingContract = false;
    contractID: number;
    contractType: string;
    contracts: Contract[];
    campaignCode: string;

    registrationOptions: RegistrationOption[];
    illustration = theme.theme === THEMES.SR ? undefined : theme.init.illustration;
    isSb1 = theme.theme === THEMES.SR;
    isEika = theme.theme === THEMES.EIKA;

    hasActiveContract: boolean;

    jobCompanyName: string;
    busyCreatingCompany: boolean;
    orgNumber: string;
    bankIdFlow = false;
    useNewOnboardingFlow = false;

    @HostBinding('style.background') background = theme.init.background || '#fff';
    @HostBinding('class.ext02') usingExt02Theme = theme.theme === THEMES.EXT02;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private errorService: ErrorService,
        private elsaContractService: ElsaContractService,
        private jobTicketService: JobTicketService,
        private initService: InitService,
        private companyService: CompanyService,
        private http: UniHttp,
    ) {
        const flowParam = +this.route.snapshot.queryParamMap.get('flow');
        if (useOnboardingFlow2(flowParam)) {
            this.useNewOnboardingFlow = true;
            return;
        }

        this.tokenSubscription = this.authService.token$.subscribe((token) => {
            if (token) {
                this.busy = true;
                this.jobTicketService.getJobTicketCompany().subscribe(
                    (res) => {
                        if (res?.length && res[0].Status?.toLowerCase() !== 'deleted') {
                            this.jobCompanyName = res[0].CompanyName;
                            this.checkCompanyCreationStatus(this.jobCompanyName);
                            return;
                        }
                    },
                    () => (this.busy = false),
                );

                this.checkBankIdFlow();

                if (!this.routeSubscription) {
                    this.routeSubscription = this.route.queryParamMap.subscribe((params) => {
                        this.isTest = params.get('isTest') === 'true' || false;
                        this.selectedCompanyType = params.get('type') || params.get('companyType') || undefined;
                        this.contractType = params.get('contractType');
                        this.orgNumber = params.get('orgno');
                        this.campaignCode = params.get('campaignCode');

                        this.init();
                    });
                } else {
                    this.init();
                }
            } else {
                this.busy = false;
                this.router.navigateByUrl('/init/login');
            }
        });
    }

    ngOnDestroy() {
        this.tokenSubscription?.unsubscribe();
        this.routeSubscription?.unsubscribe();
    }

    init() {
        const handleContract = (_contract, _contractTypes) => {
            this.contractID = _contract.ID;

            const demoContractType = (_contractTypes || []).find((type) => type.Name === 'Demo');

            const isDemo = demoContractType && _contract.ContractType === demoContractType.ContractType;
            this.hasActiveContract = !isDemo && _contract.AgreementAcceptances?.length > 0;

            this.registrationOptions = theme.theme === THEMES.SR ? this.getSb1Options() : this.getUeOptions();

            if (this.contractType) {
                this.contractType === '0'
                    ? this.router.navigateByUrl('/init/register-company?type=demo')
                    : this.router.navigateByUrl(
                          `/init/register-company?type=company&contractType=${this.contractType}`,
                      );
            }

            this.busy = false;
        };

        const requests = [this.elsaContractService.getAll(true), this.elsaContractService.getContractTypes()];

        // if contracttype isn't set in the url params
        // check if they have an active (usercreated) confirmation with contracttype
        if (!this.contractType) {
            requests.push(this.initService.getConfirmationContractType());
        }

        forkJoin(requests).subscribe(
            ([contracts, contractTypes, confirmationContractType]: any) => {
                if (confirmationContractType) {
                    this.contractType = ContractType[confirmationContractType].toString();
                }

                let contract = contracts && contracts[0];
                if (contract) {
                    handleContract(contract, contractTypes);
                } else {
                    const queryParams = this.campaignCode ? `campaignCode=${this.campaignCode}` : '';

                    this.http
                        .asPOST()
                        .usingInitDomain()
                        .withEndPoint(`register-external-user?${queryParams}`)
                        .send()
                        .subscribe(
                            () => {
                                forkJoin([
                                    this.elsaContractService.getAll(true),
                                    this.elsaContractService.getContractTypes(),
                                ]).subscribe(([contracts, contractTypes]) => {
                                    contract = contracts && contracts[0];
                                    if (contract) {
                                        handleContract(contract, contractTypes);
                                    } else {
                                        this.missingContract = true;
                                        this.busy = false;
                                    }
                                });
                            },
                            () => {
                                this.missingContract = true;
                                this.busy = false;
                            },
                        );
                }
            },
            (err) => {
                this.busy = false;
                this.errorService.handle(err);
            },
        );
    }

    private checkCompanyCreationStatus(companyName: string) {
        this.busyCreatingCompany = true;
        this.initService.getCompanies().subscribe(
            (companies) => {
                const nameLowerCase = (companyName || '').toLowerCase();
                const company = (companies || []).find((c) => {
                    return (c.Name || '').toLowerCase().includes(nameLowerCase);
                });

                if (company) {
                    this.busyCreatingCompany = false;
                    this.companyService.invalidateCache();
                    this.authService.setActiveCompany(company, '/');
                } else {
                    setTimeout(() => this.checkCompanyCreationStatus(companyName), 3000);
                }
            },
            () => setTimeout(() => this.checkCompanyCreationStatus(companyName), 3000),
        );
    }

    checkBankIdFlow() {
        const jwt = this.authService.decodedToken();
        const bankIdIdp = this.isSb1 ? 'sb1bankid' : 'bankid';
        this.bankIdFlow = !!jwt?.idp?.includes(bankIdIdp);
    }

    getUeOptions() {
        return [
            {
                header: 'Prøv et demoselskap',
                textSections: [
                    'Et demoselskap innneholder fiktive data og lar deg bli kjent med systemet før du registrerer din egen bedrift.',
                    'Fakturaer vil kun sendes til din epost adresse, og du kan naturligvis ikke betale regninger eller lønn.',
                    'Du kan når som helst i demoperioden velge å registrere din bedrift og begynne å jobbe med reelle data.',
                ],
                buttons: [
                    {
                        label: 'Opprett demoselskap',
                        action: () => this.router.navigateByUrl('/init/register-company?type=demo'),
                    },
                ],
            },
            {
                header: 'Registrer din bedrift',
                textSections: [
                    `Registrer din bedrift her for å starte opp med ${theme.appName} i dag.`,
                    this.usingExt02Theme
                        ? 'Du vil motta transaksjoner og saldo fra banken umiddelbart, og kan begynne å jobbe med reelle data.'
                        : 'Prøveperioden vil fremdeles være gratis, men du kan begynne å jobbe med reelle data umiddelbart.',
                    `Ønsker du å prøve systemet med fiktive data før du bestemmer deg velger du demoselskap.`,
                ],
                buttons: [
                    {
                        label: 'Registrer din bedrift',
                        class: 'c2a',
                        action: () => this.router.navigateByUrl('/init/register-company?type=company'),
                    },
                ],
            },
        ];
    }

    getSb1Options() {
        return [
            {
                header: 'Bestill SpareBank 1 Regnskap',
                textSections: [
                    'Registrer bedriften din og kom i gang med markedets mest komplette økonomistyringspakke!',
                    'Du kan betale regninger og lønn fra første dag. Og ja, første måned er gratis.',
                ],
                buttons: [
                    {
                        label: 'Bestill SpareBank 1 Regnskap',
                        class: 'good',
                        action: () => this.router.navigateByUrl('/init/register-company?type=company'),
                    },
                ],
            },

            {
                header: 'Prøv gratis demo i 30 dager',
                textSections: [
                    'Bli kjent med SpareBank 1 Regnskap før du bestemmer deg. Test funksjoner uten at fakturaer faktisk blir sendt eller betalinger blir utført.',
                    'Er du fornøyd, kan du bestille senere. Ingen forpliktelser!',
                ],
                buttons: [
                    {
                        label: 'Start demo med fiktiv bedrift',
                        class: 'secondary',
                        action: () => this.router.navigateByUrl('/init/register-company?type=demo'),
                    },
                ],
            },
        ];
    }
}
