import { Component, Input } from '@angular/core';
import { AuthService } from '@app/authService';
import { ISelectConfig } from '@uni-framework/ui/uniform';
import { BrowserStorageService } from '@uni-framework/core/browserStorageService';
import { Subscription, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { getQueryStringParams } from '@app/components/common/utils/utils';
import { THEMES, theme } from 'src/themes/theme';

@Component({
    selector: 'uni-company-selector',
    templateUrl: './company-selector.html',
    styleUrls: ['./company-selector.sass'],
    standalone: false,
})
export class CompanySelector {
    @Input() companies: any[];

    selectConfig: ISelectConfig = {
        displayProperty: 'Name',
        placeholder: 'Velg selskap',
        hideDeleteButton: true,
        subTextTemplate: (company) => (company.ClientNumber ? company.ClientNumber.toString() : ''),
    };

    busy = false;
    errorMessageSubscription: Subscription;

    constructor(
        public authService: AuthService,
        private browserStorage: BrowserStorageService,
        private httpClient: HttpClient,
    ) {}

    ngOnChanges(changes) {
        const lastNavigationAttempt = this.browserStorage.getItem('lastNavigationAttempt') || '/';
        const urlParams = getQueryStringParams(lastNavigationAttempt);

        if (changes.companies && this.companies) {
            if (urlParams['selectCompanyByOrgNumber']) {
                const orgnumber = urlParams['selectCompanyByOrgNumber'];
                const company = orgnumber && this.companies.find((c) => c.OrganizationNumber === orgnumber);

                if (company) {
                    this.onCompanySelected(company);
                } else if (theme.theme === THEMES.EIKA) {
                    window.location.href = 'https://www.eikaregnskap.no/ikke-kunde';
                }
            } else {
                // If the user only has access to one company we select it automatically.
                if (this.companies.length === 1) {
                    this.onCompanySelected(this.companies[0]);
                } else {
                    // Check if we have a companyKey in localStorage or in the url.
                    // Select that company if the user still has access to it.
                    const companyKey = urlParams['companyKey'] || this.browserStorage.getItem('lastActiveCompanyKey');
                    const company = companyKey && this.companies.find((c) => c.Key === companyKey);

                    if (company) {
                        this.onCompanySelected(company);
                    }
                }
            }
        }
    }

    ngOnDestroy() {
        if (this.errorMessageSubscription) {
            this.errorMessageSubscription.unsubscribe();
        }
    }

    onCompanySelected(company) {
        if (!company) return;

        this.busy = true;
        // Since the company list was fetched from /init/companies, we need to do
        // an additional request to /biz/companies to get the full company entity.
        this.getFullCompanyEnitity(company.ID, company.Key).subscribe((companyDetails) => {
            const url = this.browserStorage.getItem('lastNavigationAttempt') || '/';
            this.browserStorage.removeItem('lastNavigationAttempt');
            this.authService.setActiveCompany(companyDetails || company, url);
        });
    }

    private getFullCompanyEnitity(companyId: number, companyKey: string) {
        return this.httpClient
            .get(`/api/biz/companies/${companyId}`, {
                headers: { CompanyKey: companyKey },
            })
            .pipe(
                catchError((err) => {
                    console.error(err);
                    return of(null);
                }),
            );
    }
}
