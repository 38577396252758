import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TabService, UniModules } from '@app/components/layout/navbar/tabstrip/tabService';
import { PageStateService } from '@app/services/common/pageStateService';
import { ITableFilter } from '@uni-framework/ui/ag-grid/interfaces';

@Component({
    selector: 'sharings-list',
    templateUrl: './sharingsList.html',
    standalone: false,
})
export class SharingsList implements OnInit {
    tableFilters: ITableFilter[];

    constructor(
        private tabService: TabService,
        private pageStateService: PageStateService,
        private route: ActivatedRoute,
    ) {}

    public ngOnInit() {
        this.tabService.addTab({
            url: this.pageStateService.getUrl(),
            name: 'Utsendingsliste',
            active: true,
            moduleID: UniModules.Sharings,
        });

        this.route.queryParams.subscribe((params) => {
            if (+params['entityID']) {
                this.tableFilters = [
                    {
                        field: 'EntityID',
                        operator: 'eq',
                        value: +params['entityID'],
                    },
                ];
            }
        });
    }
}
