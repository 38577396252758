import { Component, Input } from '@angular/core';
import { AuthService } from '@app/authService';
import { theme } from 'src/themes/theme';

@Component({
    selector: 'dnb-login',
    templateUrl: './dnb-login.html',
    styleUrls: ['./dnb-login.sass'],
    standalone: false,
})
export class DnbLogin {
    @Input() isLoading: boolean;
    @Input() isAuthenticated: boolean;
    @Input() companies: any[];

    background = theme.init.login_background || theme.init.background;
    backgroundHeight = theme.init.login_background_height;
    signupLink = '/init/prelogin-register';
    bankIdEnabled: boolean;
    busy: boolean;
    bankidIdp: string;

    constructor(public authService: AuthService) {
        this.busy = true;
        this.checkBankIdExist();
    }

    checkBankIdExist() {
        this.authService.getClientIdpRestrictions().subscribe((list) => {
            this.bankIdEnabled = list?.includes('bankid') || list?.includes('bankiddnbsso');
            this.busy = false;
            if (this.bankIdEnabled) {
                this.bankidIdp = list.find((x) => x === 'bankiddnbsso') ?? list.find((x) => x === 'bankid');
            }
        });
    }

    login() {
        if (this.bankIdEnabled) {
            this.authService.authenticateWithIdp(this.bankidIdp);
        } else {
            this.authService.authenticateWithoutIdp();
        }
    }
}
