import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '@app/authService';
import { Subscription } from 'rxjs';
import { ElsaContractType } from '@app/models/elsa-models';

import { theme, THEMES } from 'src/themes/theme';
import { CelebrusService } from '@app/services/analytics-services/celebrus-service';

interface RegistrationOption {
    header: string;
    priceTag?: string;
    textSections: string[];
    footerText?: string;
    buttons: {
        label: string;
        class?: string;
        action: () => void;
    }[];
}

@Component({
    selector: 'uni-prelogin-register-company',
    templateUrl: './pre-login.html',
    styleUrls: ['./pre-login.sass'],
    standalone: false,
})
export class PreLoginRegisterCompany {
    appName = theme.appName;

    tokenSubscription: Subscription;
    routeSubscription: Subscription;
    contractTypes: ElsaContractType[];

    busy = false;

    isProspect: string;
    code: string;
    email: string;
    companyType: string;
    contractType: string;
    orgNo: string;
    campaignCode: string;

    registrationOptions: RegistrationOption[];

    @HostBinding('style.background') background = theme.init.background || '#fff';
    @HostBinding('class.ext02') usingExt02Theme = theme.theme === THEMES.EXT02;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private celebrusService: CelebrusService,
    ) {
        if (!this.usingExt02Theme) {
            this.router.navigateByUrl('/init/sign-up');
            return;
        }

        let isLoggedIn = false;
        this.tokenSubscription = this.authService.token$.subscribe((token) => {
            isLoggedIn = !!token;
        });

        this.registrationOptions = this.getOptions();

        this.routeSubscription = this.route.queryParamMap.subscribe((params) => {
            this.orgNo = params.get('orgno');
            this.companyType = params.get('type') || params.get('companyType');
            this.contractType = params.get('contractType');
            this.isProspect = params.get('isprospect');
            this.code = params.get('code');
            this.email = params.get('email');
            this.campaignCode = params.get('campaignCode');

            if (isLoggedIn) {
                const query = this.getQueryString(false);
                this.router.navigateByUrl('/init/register-company' + (query?.length ? `?${query}` : ''));
                return;
            }

            // this scenario should only occur when we arrive from the 'confirm user' email
            if (this.companyType && this.contractType) {
                this.goToSignUp(false);
                return;
            }

            if (this.companyType === 'demo') {
                this.handleDemoSelected(false);
            } else if (this.companyType === 'company') {
                this.handleCompanySelected();
            } else {
                this.celebrusService.logPageView();
            }
        });
    }

    ngOnDestroy() {
        this.tokenSubscription?.unsubscribe();
        this.routeSubscription?.unsubscribe();
    }

    async handleDemoSelected(bankid: boolean = true) {
        this.companyType = 'demo';
        await this.goToSignUp(bankid);
    }

    async handleCompanySelected() {
        this.companyType = 'company';

        const query = this.getQueryString();
        await this.router.navigateByUrl('/init/prelogin-contract' + (query.length > 0 ? `?${query}` : ''));
    }

    async goToSignUp(bankid: boolean = true) {
        await this.router.navigateByUrl(this.getSignUpUrl(bankid));
    }

    getOptions() {
        return [
            {
                header: 'Registrer din bedrift',
                textSections: [
                    `Registrer din bedrift her for å starte opp med ${theme.appName} i dag.`,
                    `Du vil motta transaksjoner og saldo fra banken umiddelbart, og kan begynne å jobbe med reelle data.`,
                    `Ønsker du å prøve systemet med fiktive data før du bestemmer deg velger du demoselskap.`,
                ],
                buttons: [
                    {
                        label: 'Registrer din bedrift',
                        class: 'c2a',
                        action: async () => await this.handleCompanySelected(),
                    },
                ],
            },
            {
                header: 'Prøv et demoselskap',
                textSections: [
                    'Et demoselskap innneholder fiktive data og lar deg bli kjent med systemet før du registrerer din egen bedrift.',
                    'Fakturaer vil kun sendes til din epost adresse, og du kan naturligvis ikke betale regninger eller lønn.',
                    'Du kan når som helst i demoperioden velge å registrere din bedrift og begynne å jobbe med reelle data.',
                ],
                buttons: [
                    {
                        label: 'Opprett demoselskap',
                        action: async () => await this.handleDemoSelected(),
                    },
                ],
            },
        ];
    }

    private getQueryString(preLogin: boolean = true): string {
        const query = new URLSearchParams();

        if (preLogin) {
            if (this.code) {
                query.append('code', this.code);
            }
            if (this.email) {
                query.append('email', this.email);
            }
            if (this.isProspect) {
                query.append('isprospect', this.isProspect);
            }
        }
        if (this.companyType) {
            query.append('companyType', this.companyType);
        }
        if (this.contractType) {
            query.append('contractType', this.contractType);
        }
        if (this.orgNo) {
            query.append('orgno', this.orgNo);
        }
        if (this.campaignCode) {
            query.append('campaignCode', this.campaignCode);
        }

        return query?.toString() ?? '';
    }

    private getSignUpUrl(bankid: boolean = true): string {
        const query = this.getQueryString().toString();

        const base = bankid ? '/init/sign-up-bankid' : '/init/sign-up';
        // Navigate to either
        if (query.length) {
            return `${base}?${query}`;
        } else {
            return base;
        }
    }
}
