import { takeUntil } from 'rxjs/operators';
import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
    selector: 'uni-password-guide',
    template: `
        <ul class="password-validation">
            <li [ngClass]="{ completed: passwordLength }">Minimum 10 tegn</li>

            <li [ngClass]="{ completed: upperCase }">Stor bokstav</li>

            <li [ngClass]="{ completed: lowerCase }">Liten bokstav</li>

            <li [ngClass]="{ completed: numOrSymbol }">Tall eller symbol</li>
        </ul>
    `,
    standalone: false,
})
export class UniPasswordGuide {
    @Input() public passwordControl: UntypedFormControl;

    public passwordLength: boolean;
    public lowerCase: boolean;
    public upperCase: boolean;
    public numOrSymbol: boolean;

    private componentDestroyed: Subject<any> = new Subject();

    public ngOnChanges() {
        if (this.passwordControl) {
            this.passwordControl.valueChanges.pipe(takeUntil(this.componentDestroyed)).subscribe((value: string) => {
                this.passwordLength = value.length >= 10;
                this.upperCase = /[A-Z]/.test(value);
                this.lowerCase = /[a-z]/.test(value);
                this.numOrSymbol = /[\d\@\#\$\%\^\&\*\-_\\+\=\[\]\{\}\|\\\:\‘\,\.\?\/\`\~\“\(\)\;]/.test(value);
            });
        }
    }

    public ngOnDestroy() {
        this.componentDestroyed.next(undefined);
    }
}
