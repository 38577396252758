import { Component, HostBinding } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthService } from '@app/authService';
import { takeUntil } from 'rxjs/operators';
import { theme, THEMES } from 'src/themes/theme';
import { APP_METADATA } from 'src/environments/metadata';

@Component({
    selector: 'uni-init',
    templateUrl: './init.html',
    standalone: false,
})
export class UniInit {
    @HostBinding('class.iOSDevice') iOSDevice = navigator.userAgent.match(/(iPod|iPhone|iPad)/);

    isAuthenticated: boolean;
    confirmed: boolean = true;
    showTryDemoLink: boolean = true;

    logoUrl = 'config/dist/theme/assets/logo.svg?v=' + APP_METADATA.APP_VERSION;
    isUnimicro = theme.theme === THEMES.UE;
    isSb1 = theme.theme === THEMES.SR;
    isDnb = theme.theme === THEMES.EXT02;
    signupLink = this.isDnb ? '/init/prelogin-register' : '/init/sign-up';

    private onDestroy$ = new Subject();

    constructor(
        private router: Router,
        public authService: AuthService,
    ) {
        this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.showTryDemoLink = this.isDnb && event.url.includes('prelogin-contract');
            }
        });

        this.authService.token$.pipe(takeUntil(this.onDestroy$)).subscribe((token) => (this.isAuthenticated = !!token));
    }

    handleDemoSelected() {
        if (this.isDnb) {
            this.authService.tryDemoEventSubject$.next();
        } else {
            this.router.navigateByUrl('/init/sign-up?companyType=demo');
        }
    }

    ngOnDestroy() {
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }

    logout() {
        this.authService.idsLogout();
    }
}
