import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/authService';
import { Subscription } from 'rxjs';
import { ElsaContractType } from '@app/models/elsa-models';
import { theme, THEMES } from 'src/themes/theme';
import { ElsaContractService } from '@app/services/elsa/elsaContractService';
import { CelebrusService } from '@app/services/analytics-services/celebrus-service';

@Component({
    selector: 'uni-prelogin-register-company-contract',
    templateUrl: './pre-login-contract.html',
    styleUrls: ['./pre-login-contract.sass'],
    standalone: false,
})
export class PreLoginRegisterCompanyContract {
    appName = theme.appName;

    tokenSubscription: Subscription;
    routeSubscription: Subscription;
    tryDemoSubscription: Subscription;
    contractTypes: ElsaContractType[];

    busy = false;

    isProspect: string;
    code: string;
    email: string;
    companyType: string;
    contractType: string;
    orgNo: string;
    campaignCode: string;

    @HostBinding('style.background') background = theme.init.background || '#fff';
    @HostBinding('class.ext02') usingExt02Theme = theme.theme === THEMES.EXT02;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private elsaContractService: ElsaContractService,
        private celebrusService: CelebrusService,
    ) {
        if (!this.usingExt02Theme) {
            this.router.navigateByUrl('/init/sign-up');
            return;
        }

        this.routeSubscription = this.route.queryParamMap.subscribe((params) => {
            this.orgNo = params.get('orgno');
            this.companyType = params.get('type') || params.get('companyType');
            this.contractType = params.get('contractType');
            this.isProspect = params.get('isprospect');
            this.code = params.get('code');
            this.email = params.get('email');
            this.campaignCode = params.get('campaignCode');

            // this scenario should only occur when we arrive from the 'confirm user' email
            if (this.companyType && this.contractType) {
                this.goToSignUp();
            } else {
                this.celebrusService.useDataLayer('PageLoaded', {
                    process: this.celebrusService.getCelebrusObject(
                        'DNB Regnskap - Register Company',
                        'Choose package',
                        'Start,Price',
                        '0',
                    ),
                });
            }
        });

        this.tokenSubscription = this.authService.token$.subscribe((token) => {
            if (token) {
                const query = this.getQueryString(false);
                this.router.navigateByUrl('/init/register-company' + (query.length ? `?${query}` : ''));
            }
        });

        this.elsaContractService.getOnboardingContractTypes().subscribe(
            (contractTypes) => {
                this.contractTypes = contractTypes || [];
                this.busy = false;
            },
            () => (this.busy = false),
        );

        this.tryDemoSubscription = this.authService.tryDemoEventSubject$.subscribe(() => {
            this.companyType = 'demo';
            this.contractType = undefined;
            this.goToSignUp();
        });
    }

    ngOnDestroy() {
        this.tokenSubscription?.unsubscribe();
        this.routeSubscription?.unsubscribe();
        this.tryDemoSubscription?.unsubscribe();
    }

    onContractTypeSelected(contracttype: number) {
        this.companyType = 'company';
        this.contractType = contracttype.toString();
        this.goToSignUp();
    }

    goToSignUp() {
        this.router.navigateByUrl(this.getSignUpUrl());
    }

    private getQueryString(preLogin: boolean = true): string {
        const query = new URLSearchParams();

        if (preLogin) {
            if (this.code) {
                query.append('code', this.code);
            }
            if (this.email) {
                query.append('email', this.email);
            }
            if (this.isProspect) {
                query.append('isprospect', this.isProspect);
            }
        }

        if (this.companyType) {
            query.append('companyType', this.companyType);
        }
        if (this.contractType) {
            query.append('contractType', this.contractType);
        }
        if (this.orgNo) {
            query.append('orgno', this.orgNo);
        }
        if (this.campaignCode) {
            query.append('campaignCode', this.campaignCode);
        }

        return query?.toString() ?? '';
    }

    private getSignUpUrl(): string {
        const query = this.getQueryString();

        // Navigate to either
        if (query.toString().length) {
            return '/init/sign-up-bankid?' + query.toString();
        } else {
            return '/init/sign-up-bankid';
        }
    }
}
