import { Component, Input } from '@angular/core';
import { AuthService } from '@app/authService';
import { ErrorService } from '@app/services/common/errorService';
import { InitService } from '@app/services/common/initService';
import { CelebrusService } from '@app/services/analytics-services/celebrus-service';
import { theme, THEMES } from 'src/themes/theme';

@Component({
    selector: 'init-new-demo',
    templateUrl: './new-demo.html',
    styleUrls: ['./new-demo.sass'],
    standalone: false,
})
export class NewDemo {
    @Input() contractID: number;
    @Input() bankIdFlow: boolean;

    creatingCompany: boolean;
    template;
    error: boolean;
    busy: boolean;

    constructor(
        private errorService: ErrorService,
        private initService: InitService,
        private authService: AuthService,
        private celebrusService: CelebrusService,
    ) {}

    ngOnChanges() {
        if (this.contractID) {
            this.initService.getTemplates().subscribe((templates) => {
                this.template = templates.find((t) => t.IsTest);
                this.createTestCompany();
            });
        }
    }

    createTestCompany() {
        this.busy = true;

        this.initService
            .createCompany({
                ContractID: this.contractID,
                TemplateCompanyKey: this.template?.Key,
                IsTest: true,
            })
            .subscribe(
                () => {
                    this.error = false;
                    this.busy = false;
                    this.creatingCompany = true;
                    this.checkCreationStatus();
                },
                (err) => {
                    switch (err.status) {
                        case 409:
                            this.checkCreationStatus();
                            break;
                        default:
                            this.errorService.handle(err);
                            this.error = true;
                            this.busy = false;
                            break;
                    }
                },
            );
    }

    private checkCreationStatus() {
        this.initService.getCompanies().subscribe(
            (companies) => {
                if (companies?.length) {
                    if (theme.theme === THEMES.EXT02) {
                        this.celebrusService.useDataLayer('PageLoaded', {
                            process: this.celebrusService.getCelebrusObject(
                                'DNB Regnskap - Demo',
                                'Receipt',
                                'Receipt',
                                '1',
                            ),
                        });
                    }

                    if (theme.theme === THEMES.SR && this.bankIdFlow) {
                        this.initService
                            .acceptUserAgreement(companies[0].Key)
                            .subscribe()
                            .add(() => this.authService.setActiveCompany(companies[0], '/'));
                    } else {
                        this.authService.setActiveCompany(companies[0], '/');
                    }
                } else {
                    setTimeout(() => this.checkCreationStatus(), 3000);
                }
            },
            () => setTimeout(() => this.checkCreationStatus(), 3000),
        );
    }
}
