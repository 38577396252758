import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { UniHttp } from '@uni-framework/core/http/http';
import { passwordValidator, passwordMatchValidator } from '../authValidators';
import { AuthService } from '@app/authService';
import { theme, THEMES } from 'src/themes/theme';
import { ContractType, ElsaAgreement } from '@app/models';
import { animate, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { ElsaAgreementService } from '@app/services/elsa/elsaAgreementService';
import { CelebrusService } from '@app/services/analytics-services/celebrus-service';
import { BankInitModal } from '@app/components/common/modals/bank-init-modal/bank-init-modal';
import { environment } from 'src/environments/environment';
import { useOnboardingFlow2 } from '@app/components/common/utils/utils';

@Component({
    selector: 'uni-signup',
    templateUrl: './signup.html',
    styleUrls: ['./signup.sass'],
    animations: [
        trigger('shake', [
            transition('false => true', [
                animate('0.1s', style({ transform: 'rotate(1deg)' })),
                animate('0.1s', style({ transform: 'rotate(-1deg)' })),
                animate('0.1s', style({ transform: 'rotate(1deg)' })),
                animate('0.1s', style({ transform: 'rotate(0)' })),
            ]),
        ]),
    ],
    standalone: false,
})
export class Signup {
    @HostBinding('class.ext02-signup') isExt02Env = theme.theme === THEMES.EXT02;
    @HostBinding('class.sb1-signup') isSb1 = theme.theme === THEMES.SR;

    isEika = theme.theme === THEMES.EIKA;
    confirmationCode: string;
    busy: boolean;

    headerText = theme.theme === THEMES.SR ? 'Registrer bruker' : 'Opprett bruker';
    appName = theme.appName;

    errorMessage: string;

    routeSubscription: Subscription;

    step1Form: UntypedFormGroup;
    step2Form: UntypedFormGroup;

    step1Successful: boolean;
    step2Successful: boolean;
    invalidConfirmationCode: boolean;
    userExists: boolean;
    showHasToAgreePrompt: boolean;

    background = theme.theme === THEMES.SR ? theme.init.login_background : theme.init.background;
    backgroundHeight = theme.init.signup_background_height;
    illustration = theme.theme === THEMES.SR ? undefined : theme.init.illustration;

    step1Subtitle: string;

    agreeementText = theme.theme === THEMES.EXT02 ? '' : 'Jeg godtar lagring og bruk av mine data';

    step2SuccessfulText =
        theme.theme === THEMES.SR
            ? 'Det er nå klart for registrering av bedriften din.'
            : `Prøveperioden er nå aktiv og alt er klart for å registrere bedriften din i ${theme.appName}.`;

    isProspect = false;
    email: string;
    hasAgreedToCustomerAgreement = false;
    customerAgreement: ElsaAgreement;
    busyAgreement = false;
    companyType: string;
    contractType: string;
    orgNumber: string;
    campaignCode: string;

    supportsBankid: boolean = false;
    bankidClicks: number = 0;
    shakeAlert: boolean = false;

    alreadyCompleted = false;

    constructor(
        public authService: AuthService,
        private http: UniHttp,
        private route: ActivatedRoute,
        private router: Router,
        private elsaAgreementService: ElsaAgreementService,
        private celebrusService: CelebrusService,
        formBuilder: UntypedFormBuilder,
    ) {
        this.step1Form = formBuilder.group({
            DisplayName: new UntypedFormControl('', Validators.required),
            Email: new UntypedFormControl('', [Validators.required, Validators.email]),
            PhoneNumber: new UntypedFormControl('', Validators.required),
            SignUpReferrer: new UntypedFormControl(this.setSignUpReferrer()),
        });

        this.step2Form = formBuilder.group(
            {
                Password: new UntypedFormControl('', [Validators.required, passwordValidator]),
                ConfirmPassword: new UntypedFormControl('', [Validators.required, passwordValidator]),
            },
            {
                validator: passwordMatchValidator,
            },
        );

        if (!this.routeSubscription) {
            this.routeSubscription = this.route.queryParams.subscribe((params) => {
                if (useOnboardingFlow2(+params['flow'])) {
                    // should just use this url in the backend, ConfirmUserUrl in appsettings
                    let route = '/init/sign-up-bankid';
                    if (params) {
                        route += `?${new URLSearchParams(params).toString()}`;
                    }
                    this.router.navigateByUrl(route);
                    return;
                }

                this.errorMessage = undefined;
                this.companyType = params['companyType'] || params['type'];
                this.contractType = params['contractType'];
                this.email = params['email'];
                this.orgNumber = params['orgno'];
                this.campaignCode = params['campaignCode'];

                if (this.companyType === 'demo') {
                    this.headerText = 'Prøv demo';
                }

                if (params['code']) {
                    this.headerText = 'Velg passord';
                    this.confirmationCode = params['code'];
                    this.validateConfirmationCode(this.confirmationCode);
                    this.isProspect = !!params['isprospect'];

                    if (!this.isProspect && !this.invalidConfirmationCode) {
                        this.step1Successful = true;
                    } else {
                        this.checkBankIdExist();
                    }

                    this.step1Form.disable();
                    this.step2Form.enable();
                    if (this.isExt02Env) {
                        this.celebrusService.useDataLayer('PageLoaded', {
                            process: this.celebrusService.getCelebrusObject(
                                'Signup post mail',
                                'Set password',
                                'start',
                                '0',
                            ),
                        });
                    }
                } else {
                    this.step1Form.enable();
                    this.step2Form.disable();
                    this.confirmationCode = null;
                    this.checkBankIdExist();
                    if (this.isExt02Env) {
                        this.celebrusService.useDataLayer('PageLoaded', {
                            process: this.celebrusService.getCelebrusObject(
                                'Signup pre mail',
                                'Personal information',
                                'start',
                                '0',
                            ),
                        });

                        this.step1Subtitle =
                            this.companyType === 'demo'
                                ? `
                        Ved å registrere profil får du en begrenset lisens til å teste
                        funksjonalitet i DNB Regnskap gratis i prøveperioden.`
                                : null;
                        this.busyAgreement = true;
                        this.elsaAgreementService.getCustomerAgreement().subscribe({
                            next: (agreement) => {
                                this.customerAgreement = agreement;
                                this.busyAgreement = false;
                            },
                            error: () => (this.busyAgreement = false),
                        });
                    }
                }
            });
        }
    }

    ngOnDestroy() {
        this.routeSubscription?.unsubscribe();
    }

    setSignUpReferrer(): string {
        const ref = document.referrer;
        if (!ref?.length || ref.includes('assets/auth.html') || window.location.href.includes(ref)) {
            return null;
        }
        return ref;
    }

    checkBankIdExist() {
        this.authService.getClientIdpRestrictions().subscribe((list) => {
            this.supportsBankid = list?.includes('bankid');
        });
    }

    public submitStep1Form() {
        if (!this.hasAgreedToCustomerAgreement && this.isExt02Env && this.companyType !== 'demo') {
            this.showHasToAgreePrompt = true;
            this.bankidClicks++;

            if (this.bankidClicks % 2 === 0) {
                this.shakeAlert = true;
            }
            return;
        }

        this.step1Form.disable();
        this.busy = true;
        this.errorMessage = '';
        this.bankidClicks = 0;

        const body = this.step1Form.value;

        body.OptionalParams = {};
        if (this.contractType) {
            body.OptionalParams.contractType = this.contractType;
            body.ContractType = ContractType[this.contractType];
        }

        if (this.companyType) {
            body.OptionalParams.type = this.companyType;
            if (this.companyType.toLowerCase() === 'demo') {
                body.ContractType = 'Demo';
            }
        }

        if (this.campaignCode) {
            body.CampaignCode = this.campaignCode;
            body.OptionalParams.campaignCode = this.campaignCode;
        }

        this.http
            .asPOST()
            .usingInitDomain()
            .withEndPoint('sign-up')
            .withBody(body)
            .send()
            .subscribe({
                next: (response) => {
                    this.busy = false;
                    this.step1Successful = true;
                    this.headerText = 'E-post sendt';

                    // will probably be replaced by an email in spin-1501
                    if (response?.body?.toLowerCase().includes('already completed')) {
                        this.alreadyCompleted = true;
                        this.headerText = 'Bruker finnes fra før';
                    }
                    if (theme.theme === THEMES.EXT02) {
                        this.celebrusService.useDataLayer('PageLoaded', {
                            process: this.celebrusService.getCelebrusObject(
                                'Signup pre mail',
                                'E-mail sent',
                                'receipt',
                                '1',
                            ),
                        });
                    }
                },
                error: (err) => {
                    this.step1Form.enable();
                    this.busy = false;
                    this.step1Successful = false;

                    this.errorMessage = err?.error?.Message;
                    if (!this.errorMessage) {
                        this.errorMessage = 'Noe gikk galt under verifisering. Vennligst sjekk detaljer og prøv igjen.';
                    }
                },
            });
    }

    public submitStep2Form() {
        if (this.busy) {
            return;
        }

        if (!this.step2Form.valid) {
            this.errorMessage =
                'Skjemaet er ikke gyldig. Vennligst påse at alle felter er fylt ut i henhold til kravene.';

            this.step2Form.controls.Password.markAsTouched();
            this.step2Form.controls.ConfirmPassword.markAsTouched();

            return;
        }

        this.errorMessage = '';
        this.busy = true;
        const formValues = this.step2Form.value;

        const requestBody = {
            Password: formValues.Password,
            ConfirmationCode: this.confirmationCode,
            CampaignCode: this.campaignCode,
        };

        this.http
            .asPOST()
            .usingInitDomain()
            .withEndPoint('register-user')
            .withBody(requestBody)
            .send()
            .subscribe({
                next: () => {
                    this.step2Successful = true;
                    this.headerText = 'Brukerregistrering fullført';
                    if (theme.theme === THEMES.EXT02) {
                        this.celebrusService.useDataLayer('PageLoaded', {
                            process: this.celebrusService.getCelebrusObject(
                                'Signup post mail',
                                'Receipt',
                                'receipt',
                                '1',
                            ),
                        });

                        if (this.companyType === 'company' && this.contractType === '11') {
                            this.step2SuccessfulText = 'Alt er klart for å registrere regnskapsbyrået i DNB Regnskap.';
                        }
                    }
                },
                error: (err) => {
                    this.busy = false;
                    this.step2Successful = false;
                    let errorMessage;

                    try {
                        const errorBody = err.error;
                        errorMessage = errorBody.Message || errorBody.Messages[0].Message;
                    } catch (error) {}

                    this.errorMessage = errorMessage || 'Noe gikk galt under registrering, vennligst prøv igjen';
                },
            });
    }

    public validateConfirmationCode(code) {
        this.busy = true;
        this.http
            .asGET()
            .usingInitDomain()
            .withEndPoint(`validate-confirmation?code=${code}`)
            .send()
            .subscribe({
                next: () => {
                    this.invalidConfirmationCode = false;
                    this.busy = false;
                },
                error: (err) => {
                    let errorMsg = err.error?.Message || err.error?.Messages[0]?.Message;

                    this.headerText = 'En feil oppstod';
                    if (errorMsg?.toLowerCase().includes('user already')) {
                        this.userExists = true;
                        this.errorMessage = 'Du er allerede registrert. Vennligst gå til innloggingssiden.';
                    } else {
                        this.errorMessage = 'Bekreftelseskoden er utløpt. Vennligst prøv å registrere deg igjen.';
                        this.invalidConfirmationCode = true;
                    }

                    this.busy = false;
                },
            });
    }

    login() {
        this.authService.authenticate({
            data: { companyType: this.companyType, contractType: this.contractType },
        });
    }

    goToBankIdSignUp(bankidIdp?: string) {
        const query = this.getQueryString(true, bankidIdp);
        this.router.navigateByUrl('/init/sign-up-bankid' + (query?.length ? `?${query}` : ''));
    }

    openAgreement() {
        window.open(this.customerAgreement.DownloadUrl, '_blank');
    }

    openDnbAgreement() {
        window.open('https://dnb.no/om-oss/personvern.html', '_blank');
    }

    backToRegisterButton() {
        this.headerText = this.isSb1 ? 'Registrer bruker' : 'Opprett bruker';
        this.invalidConfirmationCode = false;
    }

    private getQueryString(preLogin: boolean = true, bankidIdp?: string): string {
        const query = new URLSearchParams();

        if (preLogin) {
            if (this.confirmationCode) {
                query.append('code', this.confirmationCode);
            }
            if (this.email) {
                query.append('email', this.email);
            }
            if (this.isProspect) {
                query.append('isprospect', `${this.isProspect}`);
            }
        }

        if (this.companyType) {
            query.append('companyType', this.companyType);
        }
        if (this.contractType) {
            query.append('contractType', this.contractType);
        }
        if (this.orgNumber) {
            query.append('orgno', this.orgNumber);
        }
        if (this.campaignCode) {
            query.append('campaignCode', this.campaignCode);
        }
        if (bankidIdp) {
            query.append('bankidIdp', bankidIdp);
        }

        return query?.toString() ?? '';
    }
}
