import { Component, ViewChild, HostBinding } from '@angular/core';
import { AuthService } from '@app/authService';
import { UniHttp } from '@uni-framework/core/http/http';
import { ISelectConfig, UniSelect } from '@uni-framework/ui/uniform';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { theme, THEMES } from 'src/themes/theme';
import { useOnboardingFlow2 } from '@app/components/common/utils/utils';

@Component({
    selector: 'uni-login',
    templateUrl: './login-wrapper.html',
    styleUrls: ['./login-wrapper.sass'],
    standalone: false,
})
export class Login {
    @ViewChild(UniSelect) select: UniSelect;
    @HostBinding('class.sr-login') srLogin = theme.theme === THEMES.SR;

    isBruno = theme.theme === THEMES.EXT02;
    isEika = theme.theme === THEMES.EIKA;

    theme = theme.theme;
    themes = THEMES;

    isAuthenticated: boolean;
    isLoading: boolean;
    availableCompanies: any[];
    companyErrorMessage = '';

    background = theme.init.login_background || theme.init.background;
    backgroundHeight = theme.init.login_background_height;
    illustration = theme.theme === THEMES.SR ? undefined : theme.init.illustration;
    isSb1 = theme.theme === THEMES.SR;

    selectConfig: ISelectConfig = {
        displayProperty: 'Name',
        placeholder: 'Velg selskap',
        hideDeleteButton: true,
    };

    tokenSubscription: Subscription;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public authService: AuthService,
        private http: UniHttp,
    ) {
        this.tokenSubscription = this.authService.token$.subscribe((token) => {
            this.isAuthenticated = !!token;
            if (this.isAuthenticated) {
                this.isLoading = true;
                const params = this.route.snapshot.queryParams;

                if (params['contractActivation']) {
                    let route = '/contract-activation?contractActivation=true';
                    this.router.navigateByUrl(route);
                    return;
                } else if (params['isSignUp'] && useOnboardingFlow2(+params['flow'])) {
                    // need to make sure this 'else if' doesn't break the old flow
                    this.redirectToRegisterCompany(params);
                    return;
                } else if (params['inviteCode']) {
                    this.registerExternalUser(params['inviteCode']).subscribe({
                        next: () => this.loadCompanies(),
                        error: (err) => {
                            console.error(err);
                            this.isLoading = false;
                            this.isAuthenticated = false;
                            this.authService.idsLogout();
                        },
                    });
                } else {
                    this.loadCompanies(params);
                }
            } else {
                const idpLogin = localStorage.getItem('redirectLoginIdp');
                if (idpLogin) {
                    localStorage.removeItem('redirectLoginIdp');
                    this.authService.authenticateWithIdp(idpLogin);
                }
            }
        });
    }

    ngOnDestroy() {
        if (this.tokenSubscription) {
            this.tokenSubscription.unsubscribe();
        }
    }

    private redirectToRegisterCompany(params?: any) {
        let route = '/init/register-company';
        if (params) {
            route += `?${new URLSearchParams(params).toString()}`;
        }
        this.router.navigateByUrl(route);
    }

    private async loadCompanies(params?: any) {
        this.http
            .asGET()
            .usingInitDomain()
            .withEndPoint('companies')
            .send()
            .subscribe({
                next: (res) => {
                    this.availableCompanies = res.body?.sort((a, b) => a.Name.localeCompare(b.Name));

                    const isLocalhostOrPlayground =
                        location.hostname === 'localhost' || location.hostname.includes('playgrounds.');
                    if (!this.availableCompanies?.length && theme.theme === THEMES.EIKA && !isLocalhostOrPlayground) {
                        window.location.href = 'https://www.eikaregnskap.no/ikke-kunde';
                        return;
                    }

                    if (!this.availableCompanies?.length && theme.theme !== THEMES.AZETS) {
                        this.redirectToRegisterCompany(params);
                        return;
                    }

                    this.isLoading = false;
                },
                error: (err) => {
                    console.error(err);
                    this.isLoading = false;
                    this.isAuthenticated = false;
                    this.authService.idsLogout();
                },
            });
    }

    private registerExternalUser(inviteCode: string) {
        return this.http
            .asPOST()
            .usingInitDomain()
            .withEndPoint('register-external-user?code=' + inviteCode)
            .send();
    }
}
